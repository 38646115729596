.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* background-color: rgb(67, 67, 117); */
  height: 6rem;
  width: 100%;
  padding: 0 6rem;
}
.header > div:first-child {
  display: flex;
}
.header__logoTL:first-child {
  margin-right: 1rem;
}
#logo {
  width: 70%;
}
.header > div:first-child img:last-child {
  width: 19rem;
}
.header > div:last-child img {
  width: 2.7rem;
}
.header > div:last-child img:nth-child(2) {
  margin: 0 0.8rem;
}
.header__icons > a:nth-child(2) {
  margin: 0 2rem;
}
@media (max-width: 986px) {
  .header {
    flex-direction: column;
    align-items: stretch;
    padding: 0 1.5rem;
  }
  .header > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }
  .header > div:last-child {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2.2rem;
  }
  #logo {
    width: 8.5rem;
  }
  #logo_alianza {
    width: 6.5rem;
  }
  .icon {
    /* background-color: thistle; */
    max-width: 2.3rem;
  }
  .header__icons > a:nth-child(2) {
    margin: 0 1.2rem;
  }
}
