.form-control {
  width: 100%;
  border-radius: 8px;
  color: #5f5f5f;
  font-family: "Barlow-Regular";
  border-color: #5f5f5f83;
  background: transparent;
  outline: none;
}
input.form-control:focus {
  border-color: #8836f7;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

@media (max-width: 768px) {
  .form-control {
    font-size: 14px;
    height: 2.5rem;
  }
}
