.strategies {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.3rem 5rem;
  background-color: #f4f3f2;
  font-family: "Barlow-Regular";
}
.strategies h1 {
  text-align: center;
  color: #5f01d0;
  font-size: 45px;
  font-family: "Barlow-ExtraBold";
}
.strategies h3 {
  text-align: center;
  color: #000000;
  font-family: "Barlow-Bold";
  font-size: 32px;
  margin-top: 2rem;
}
.strategies p {
  text-align: center;
  color: #808080;
  font-family: "Barlow-Regular";
  font-size: 20px;
}
.strategies h5 {
  text-align: center;
  color: #000000;
  font-family: "Barlow-Regular";
  font-size: 16px;
}
.strategies__image {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.strategies__image img {
  width: 90%;
}
