.floating__button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.floating__button img {
  width: 3.8rem;
  transition: 0.5s all;
}
.floating__button img:hover {
  transform: scale(1.2);
}
@media (max-width: 986px) {
  .floating__button {
    margin-bottom: 1.8rem;
    margin-right: 1rem;
  }
  .floating__button img {
    width: 3.2rem;
  }
}
