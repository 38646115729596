.courses__card {
  height: auto;
  min-height: 42.1rem;
  border: none;
  border-radius: 0;
  font-size: 16px;
}
.card-img-top {
  cursor: pointer;
}
.card-img-wrap {
  overflow: hidden;
  position: relative;
}
.card-img-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.card-img-wrap > img:first-child {
  transition: transform 2s;
  width: 100%;
}
.card-img-wrap:hover > img:first-child {
  transform: scale(1.2);
}
.card-img-wrap:hover:after {
  opacity: 1;
}
.card-img-video {
  position: absolute;
  width: 4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  cursor: pointer;
}
.card-body {
  padding-bottom: 0;
}
.card-title {
  font-family: "Barlow-Medium";
  font-size: 1.4em;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.card-name {
  font-family: "Barlow-Regular";
  font-size: 1.1875em;
  color: #5f01d0;
  /* line-height: 15px; */
  /* margin-bottom: 1rem; */
  /* padding-right: 1rem; */
}
.card-guest {
  font-family: "Barlow-Regular";
  font-size: 1.1875em;
  color: #5f01d0;
  line-height: 15px;
  margin-bottom: 2rem;
}
.card-description {
  font-family: "Barlow-Regular";
  font-size: 1em;
  line-height: 21px;
}
.card-action {
  /* background-color: khaki; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  /* margin-bottom: 1.3rem; */
}
.card-time {
  font-family: "Barlow-Regular";
  /* font-weight: 500; */
  font-size: 1.375em;
  color: #8836f7;
}
.card-button {
  cursor: pointer;
  margin-top: 0.7rem;
  width: 100%;
  transition: 0.5s;
}
.card-button a {
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1.75em;
  background-color: #5f01d0;
  font-family: "Barlow-SemiBold";
}
.card-button a:hover {
  background-color: #5405b4;
}
.card-button img {
  width: 1.8rem;
}
.courses__img--zoom {
  position: absolute;
  /* left: 0; */
  /* background-color: rgb(51, 51, 204); */
  width: 100%;
  height: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}
.courses__img--zoom img {
  width: 4rem;
  cursor: pointer;
}

@media (max-width: 986px) {
  .courses__card {
    font-size: 14px;
    min-height: 35rem;
  }
  .banner {
    padding: 3.3rem 0.9rem;
    height: auto;
  }
  .banner_text {
    font-size: 18px;
    line-height: 24px;
  }
}
