@font-face {
  font-family: "Barlow-Bold";
  src: url("./assets/fonts/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-ExtraBold";
  src: url("./assets/fonts/Barlow-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-Medium";
  src: url("./assets/fonts/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-Regular";
  src: url("./assets/fonts/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow-SemiBold";
  src: url("./assets/fonts/Barlow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "DINPro-Bold";
  src: url("./assets/fonts/DINPro-Bold.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Medium";
  src: url("./assets/fonts/DINPro-Medium.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Regular";
  src: url("./assets/fonts/DINPro-Regular.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
