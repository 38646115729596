.courses {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #f4f3f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  z-index: 3;
}
.courses > div:first-child {
  font-family: "Barlow-SemiBold";
  font-size: 25px;
}
.courses > div:nth-child(2) {
  font-family: "Barlow-ExtraBold";
  font-size: 35px;
  color: #5f01d0;
  margin-top: -0.5rem;
}
.container__courses {
  padding: 0 5rem;
}
.offer {
  width: 100%;
  /* height: 9rem; */
  background: rgb(255, 104, 45);
  background: linear-gradient(
    90deg,
    rgba(255, 104, 45, 1) 5%,
    rgba(255, 1, 154, 1) 100%
  );
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  margin-bottom: 4rem;
}
.offer h1 {
  font-family: "Barlow-Bold";
}
.offer__container {
  display: flex;
}
.offer__container > div:nth-child(2) {
  border-right: 2px solid rgb(255, 255, 255);
  border-left: 2px solid rgb(255, 255, 255);
  margin: 0 2rem;
  padding: 0 2rem;
}
.offer h4 {
  font-family: "Barlow-Regular";
}
.offer__button a {
  font-size: 25px;
  padding: 0.35rem 2.5rem;
  margin-left: 4rem;
  font-family: "Barlow-SemiBold";
}
.img__fondo {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.img__fondo img {
  width: 65rem;
  margin-left: -18rem;
  margin-bottom: -23rem;
}

@media (max-width: 986px) {
  .courses > div:first-child {
    font-size: 16px;
    font-weight: 600;
  }
  .courses > div:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
    color: #5f01d0;
  }
  .container__courses {
    padding: 0 1.5rem;
  }
  .offer {
    flex-direction: column;
    padding: 2rem 1rem;
  }
  .offer h1 {
    font-size: 22px;
  }
  .offer h4 {
    font-size: 16px;
    margin-bottom: 1.5rem;
  }
  .offer__button {
    background-color: rgb(139, 139, 50);
    width: 100%;
  }
  .offer__button a {
    margin-left: 0;
    font-size: 19px;
    width: 100%;
  }
  .offer__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .offer__container > div:nth-child(2) {
    border-right: none;
    border-left: none;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    margin: 1rem 0;
    padding: 1rem 0;
  }
}
