.banner {
  /* position: absolute; */
  width: 100%;
  height: 9rem;
  background: rgb(255, 104, 45);
  background: linear-gradient(
    90deg,
    rgba(255, 104, 45, 1) 5%,
    rgba(255, 1, 154, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 6rem;
}
.banner_text {
  font-family: "Barlow-Medium";
  text-align: center;
  font-size: 27px;
  color: white;
  line-height: 34px;
}
@media (max-width: 986px) {
  .banner {
    padding: 3.3rem 0.9rem;
    height: auto;
  }
  .banner_text {
    font-size: 18px;
    line-height: 24px;
  }
}
