.banner {
  /* position: absolute; */
  width: 100%;
  height: auto;
  background: rgb(14, 249, 199);
  background: linear-gradient(
    90deg,
    rgba(14, 249, 199, 1) 0%,
    rgba(30, 227, 207, 1) 28%,
    rgba(30, 203, 207, 1) 99%
  );

  padding: 1.5rem 6rem;
  font-size: 16px;
}
.banner_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 1rem 0rem;
  /* line-height: 24px; */
}
.banner_text > h2 {
  color: black;
  font-size: 1.75em;
  font-family: "Barlow-SemiBold";
}
.banner_text > h1 {
  color: #5f01d0;
  font-size: 2.875em;
  font-family: "Barlow-ExtraBold";
  margin-top: -0.5rem;
}
.banner_text > h3 {
  color: #5f01d0;
  font-size: 1.6375em;
  font-family: "Barlow-SemiBold";
  margin-top: 0rem;
}

@media (max-width: 986px) {
  .banner {
    padding: 0.6rem 0.7rem;
    height: auto;
    font-size: 11px;
  }
}
