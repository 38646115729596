.footer {
  width: 100%;
  height: auto;
  background: rgb(51, 1, 172);
  background: linear-gradient(
    90deg,
    rgba(51, 1, 172, 1) 0%,
    rgba(95, 1, 208, 1) 42%,
    rgba(149, 57, 254, 1) 99%
  );
  font-family: "Barlow-Regular";
  padding: 2rem 2rem;
}
.footer__links {
  display: grid;
  gap: 0rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.footer__links > div {
  /* background-color: thistle; */
  text-align: center;
  width: 100%;
}
.footer__links > div:nth-child(1) {
  border-right: white solid 2px;
}
.footer__links > div:nth-child(2) {
  border-right: white solid 2px;
}
.footer__links > div:nth-child(3) {
  border-right: white solid 2px;
}
.footer__links > div:nth-child(4) img {
  width: 16rem;
}
.footer__links > div img {
  width: 10rem;
}
.footer__icons {
  display: flex;
  justify-content: center;
}
.footer__icons > div:nth-child(2) {
  margin: 0 1.8rem;
}
.footer__icons > div img {
  width: 75%;
}
.footer__terms {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  text-align: center;
  font-family: "Barlow-Regular";
}
.footer__terms > div {
  color: white;
  font-size: 13px;
}
.footer__terms > div:nth-child(2) {
  margin: 0 2rem;
  text-decoration: underline;
}
.footer__terms > div:nth-child(3) {
  text-decoration: underline;
}
a {
  text-decoration: none;
  color: white;
}
@media (max-width: 986px) {
  .footer__links {
    display: flex;
    flex-direction: column;
  }
  .footer__links > div:nth-child(1) {
    border-right: none;
  }
  .footer__links > div:nth-child(2) {
    border-right: none;
    margin: 1.3rem 0 0.3rem 0;
  }
  .footer__links > div:nth-child(3) {
    border-right: none;
    margin-bottom: 2rem;
  }
  .footer__links > div > img {
    width: 8rem;
  }
  .footer__terms {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__terms > div:nth-child(2) {
    margin: 0.8rem 2rem;
    text-decoration: underline;
  }
  .footer__icons > div:nth-child(2) {
    margin: 0 1rem;
  }
  .footer__icons > div img {
    width: 55%;
  }
}
