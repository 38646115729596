.container-text {
  background-color: #5f01d0;
  width: 100%;
  padding: 2rem 0rem 0.7rem 0rem;
}
.title-modal-home {
  font-family: "DINPro-Bold";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-style: normal;
  font-size: 30px;
  /* margin-top: 1rem; */
}
.message-modal-home {
  font-family: "DINPro-Regular";
  /* letter-spacing: 0px; */
  color: #ffffff;
  opacity: 1;
  font-size: 26px;
  line-height: 30px;
  /* margin-top: 1.5rem; */
}
.modal-content {
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}
.modal-body {
  padding: 0rem !important;
}
.modal-lg.modal-home {
  max-width: 30rem;
  padding: 0 !important;
}
.modal-footer {
  border-top: 0 none;
  /* padding-bottom: 2rem; */
  align-items: center;
  align-content: center;
}
.btn-modal {
  background-color: #ffc400;
  padding: 8px 1.8rem;
  color: white;
  font-family: "D-DIN-Bold";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: rgba(15, 15, 15, 0.507) 2px 4px 7px 0px,
    rgba(56, 56, 56, 0) -6px -2px 16px 0px;
  border-radius: 8px;
  letter-spacing: 0.6px;
  border: none;
}
.btn-modal:hover {
  background-color: #e9b713;
  color: white;
}
.inscription__inputs-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}
.inscription__inputs-home > form {
  width: 75%;
}
.inscription__home--button {
  /* background: rgb(255, 104, 45); */
  background: linear-gradient(
    90deg,
    rgba(255, 104, 45, 1) 5%,
    rgba(255, 1, 154, 1) 100%
  );
  padding: 0.4rem 0rem;
  color: white;
  font-family: "Barlow-SemiBold";
  font-size: 1.9em;
  letter-spacing: 0.5px;
  box-shadow: rgba(15, 15, 15, 0.507) 2px 4px 7px 0px,
    rgba(56, 56, 56, 0) -6px -2px 16px 0px;
  border-radius: 8px;
  letter-spacing: 0.6px;
}
.inscription__home--button:hover {
  background: rgba(255, 104, 45, 1);
  color: white;
}
.modal-close {
  width: 15px;
  margin: 8px;
  cursor: pointer;
  transition: all 0.2s;
}
.modal-close:hover {
  transform: scale(1.2);
}
@media (max-width: 768px) {
  .title-modal-home {
    font-size: 23px;
  }
  .message-modal-home {
    font-size: 20px;
  }
}
