.container__home {
  position: absolute;
  height: auto;
  min-height: 42rem;
  width: 100%;
  font-size: 20px;
  z-index: 3;
}
.container__home_background {
  /* position: absolute; */
  height: 100vh;
  min-height: 42rem;
  width: 100%;
  background-image: url("../../../assets/images/fondo_inicio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0.9;
  overflow-y: hidden;
}
.container__home_banner {
  position: absolute;
  height: 100vh;
  min-height: 42rem;
  width: 100%;
  background-image: url("../../../assets/images/cinta_banner_inicio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  z-index: 1;
}
