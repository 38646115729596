.home {
  /* background-color: lightgreen; */
  padding: 5rem 0 0 6rem;
  height: calc(100vh - 6rem);
  width: 100%;
  display: flex;
}
.home__left {
  width: 40%;
  margin-top: 9rem;
}
.home__left img {
  width: 90%;
}
.home__left_text {
  color: white;
  width: 90%;
  font-size: 25px;
}
.home__right {
  width: 60%;
  margin-top: -6rem;
}
.home__right img {
  width: 84%;
}
@media (max-width: 986px) {
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .home__left {
    width: 100%;
    margin-top: 2rem;
    padding: 1.8rem;
    /* background-color: rgb(141, 116, 141); */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
  }
  .home__left:first-child {
    text-align: center;
  }
  .home__left img {
    width: 85%;
  }
  .home__right {
    width: 100%;
    margin-top: -2rem;
    display: flex;
    justify-content: center;
    /* background-color: tomato; */
  }
  .home__right img {
    width: 87%;
  }
  .home__left_text {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding: 0.4rem 2rem 0rem 2rem;
    line-height: 20px;
    /* background-color: tomato; */
  }
}
