.diplomat {
  width: 100%;
  height: auto;
  background: rgb(51, 1, 172);
  background: linear-gradient(
    90deg,
    rgba(51, 1, 172, 1) 0%,
    rgba(95, 1, 208, 1) 42%,
    rgba(149, 57, 254, 1) 99%
  );

  padding: 0rem 4rem;
  font-size: 16px;
  display: flex;
}
.diplomat_image {
  width: 50%;
  /* background-color: lawngreen; */
}
.diplomat_image img {
  width: 100%;
}

.diplomat_info {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Barlow-Regular";
}
.diplomat_info h1 {
  font-family: "Barlow-Bold";
  font-size: 47px;
}
.diplomat_info h3 {
  margin-top: 2rem;
  font-size: 25px;
  font-family: "Barlow-Regular";
}
.subscribe {
  background: rgb(255, 104, 45);
  background: linear-gradient(
    90deg,
    rgba(255, 104, 45, 1) 5%,
    rgba(255, 1, 154, 1) 100%
  );
  padding: 1.5rem 1.7rem 1.8rem 1.7rem;
  margin-top: 2.2rem;
}
.subscribe h2 {
  font-size: 35px;
}
.subscribe > div a {
  font-size: 23px;
  padding: 0 3rem;
  font-weight: 600;
  margin-top: 0.4rem;
  font-family: "Barlow-Medium";
}
.titleMobile {
  text-align: center;
  color: white;
  margin-top: 2rem;

  font-family: "Barlow-Regular";
  padding: 0 0.7rem;
}
.titleMobile h4 {
  font-size: 16px;
}
.titleMobile h2 {
  font-size: 30px;
  margin-top: -0.5rem;
  font-family: "Barlow-Bold";
}
.subscribeMobile {
  background: rgb(255, 104, 45);
  background: linear-gradient(
    90deg,
    rgba(255, 104, 45, 1) 5%,
    rgba(255, 1, 154, 1) 100%
  );
  padding: 0 0 1.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Barlow-Regular";
}
.subscribeMobile h3 {
  font-size: 17px;
}
.subscribeMobile h2 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 1.3rem;
}
.subscribeMobile > div {
  width: 75%;
  display: flex;
  justify-content: center;
}
.subscribeMobile > div a {
  width: 100%;
  font-size: 20px;
  padding: 0.4rem 3rem;
  font-family: "Barlow-SemiBold";
}

@media (max-width: 986px) {
  .diplomat {
    flex-direction: column;
    align-items: center;
    padding: 0rem 0rem;
    margin-bottom: 3rem;
  }
  .diplomat_image {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .diplomat_image img {
    width: 80%;
  }
  .diplomat_info {
    width: 100%;
  }
  .diplomat_info > div {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}
